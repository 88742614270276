import axios from "axios";
import { forEach, isArray, isFunction, isPlainObject } from 'lodash'
import { orgin } from './config';
function isPC() {
  var userAgentInfo = navigator.userAgent;
  var Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod", "ArkWeb", "Harmony"];
  var flagPc = true;
  for (var v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flagPc = false;
      break;
    }
  }
  return flagPc;
}

function isIos() {
  var userAgent = navigator.userAgent;
  var isiOS = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  // 　　var isAndroid = userAgent.indexOf('Android') > -1 || userAgent.indexOf('Adr') > -1;
  return isiOS
}

function geturlparam() {
  let url = window.location.href
  let p = url.split('?')[1]
  let params = new URLSearchParams(p)
  return params
}

function rotateScreen(ref, evt, backFunc) {
  let width = document.documentElement.clientWidth;
  let height = document.documentElement.clientHeight;
  if (width < height) {
    backFunc && backFunc(true)
    ref.current.style.width = height + "px"
    ref.current.style.height = width + "px"
    ref.current.style.top = (height - width) / 2 + "px"
    ref.current.style.left = 0 - (height - width) / 2 + "px"
    ref.current.style.transform = 'rotate(90deg)'
    ref.current.style.transformOrigin = '50% 50%'
  } else {
    backFunc && backFunc(false)
  }
  console.log(evt, 'evt')
  window.addEventListener(evt, function () {
    setTimeout(() => {
      let width = document.documentElement.clientWidth;
      let height = document.documentElement.clientHeight;
      if (width > height) {
        backFunc && backFunc(false)
        if (ref.current) {
          ref.current.style.width = width + "px"
          ref.current.style.height = height + "px"
          ref.current.style.top = 0
          ref.current.style.left = 0
          ref.current.style.transform = 'none'
          ref.current.style.transformOrigin = '50% 50%'
        }
      } else {
        backFunc && backFunc(true)
        if (ref.current) {
          ref.current.style.width = height + "px"
          ref.current.style.height = width + "px"
          ref.current.style.top = (height - width) / 2 + "px"
          ref.current.style.left = 0 - (height - width) / 2 + "px"
          ref.current.style.transform = 'rotate(90deg)'
          ref.current.style.transformOrigin = '50% 50%'
        }
      }
    }, 500)
  }, false);
}

function getAllSource() {
  let type = "PC,H5"
  if (!isPC()) {
    let sourceType = geturlparam().get("sourceType")
    if (sourceType === 'app') {
      type = "PC,APP"
    }
  }
  return axios.post(orgin + '/resources-config/list', {
    dataId: "",
    type,
    resoruceType: "",
    sceneId: geturlparam().get("sceneId")
  }).then(res => {
    let obj = {time:new Date().getTime()};
    if (res.data) {
      let dataSource = res.data.data;
      forEach(dataSource, item => {
        const { children } = item;
        obj[item.url] = {}
        if (isArray(children) && children.length > 0) {
          forEach(children, child => {
            const { dataId, resourceType, content, url, pid } = child
            if (resourceType === 'video') {
              obj[item.url][dataId] = url
              if (pid) {
                obj[item.url].vdnUrl = url
              }
            } else if (resourceType === 'image') {
              obj[item.url][dataId] = url
            } else if (resourceType === 'text') {
              obj[item.url][dataId] = content
            }
          })
        }
      })
    }
    return obj
  })
}
function initSource(callback) {
  let sourceStr = sessionStorage.getItem("source")
  const sources = JSON.parse(sourceStr)
  const { pathname } = window.location
  if (!sourceStr) {
    getAllSource().then(res => {
      sessionStorage.setItem("source", JSON.stringify(res))
      if (callback && isFunction) {
        callback(res[pathname])
      }
    })
  }else if(!getDistanceDay(sources.time)){
    getAllSource().then(res => {
      sessionStorage.setItem("source", JSON.stringify(res))
      if (callback && isFunction) {
        callback(res[pathname])
      }
    })
  } else {
    if (isPlainObject(sources)) {
      const source = sources[pathname]
      if (callback && isFunction) {
        callback(source)
      }
    }
  }
}

// 判断是否过期，过期时间四个小时
function getDistanceDay(oldTime){
  let nowTimw = new Date().getTime()
  let one_hour = 60 * 60 * 1000;
let usedTime = nowTimw - oldTime; //两个时间戳相差的毫秒数
  if(parseInt(usedTime / one_hour)<4){
      return true
  }else{
      return false
  }
}
function getRttObj(e = 0) {
  e = e - 0
  if (e < 100) {
    return {
      url: "https://img.alicdn.com/imgextra/i1/O1CN01Cng5QY1uN2jXbR6Q5_!!6000000006024-2-tps-42-40.png",
      color: "#14AD87"
    }
  }
  if (100 <= e < 400) {
    return {
      url: "https://img.alicdn.com/imgextra/i4/O1CN01lZkcsd1MyQ7dWeZU6_!!6000000001503-2-tps-42-40.png",
      color: "#AD7314"
    }
  }
  return {
    url: "https://img.alicdn.com/imgextra/i4/O1CN01TWCcVk1Q1ZhFzJFCx_!!6000000001916-2-tps-40-40.png",
    color: "#FF3000"
  }
}

function getCdnUrl(url,callback){
  axios.get(url).then((res)=>{
    console.log(res)
    const { video, status } = res.data
    if(status !== "001"){
      return false
    }
    const  chaptersObj = video.chapters4 || video.chapters3 || video.chapters2 || video.chapters || []
    const { image, url } = chaptersObj[0] || {}
    callback&&callback({poster:image,video:url})
  })
}
export { isPC, geturlparam, rotateScreen, isIos, initSource, getRttObj, getCdnUrl }
